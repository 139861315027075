// extracted by mini-css-extract-plugin
export var appleBtn = "styles-module--appleBtn--LSKa9";
export var btnLearnMore = "styles-module--btnLearnMore--JwjDj";
export var button_place_name = "styles-module--button_place_name--6UdEt";
export var email_block = "styles-module--email_block--4exl7";
export var facebook = "styles-module--facebook--rfBXR";
export var google = "styles-module--google--3Q9m8";
export var googleBtn = "styles-module--googleBtn--zJ0v+";
export var instagram = "styles-module--instagram--CkkR-";
export var scrollBtn = "styles-module--scrollBtn--p4kIi";
export var socialIcons_block = "styles-module--socialIcons_block--ot-r-";
export var subtract = "styles-module--subtract--0rgdc";