import React from 'react';
import * as styles from "./styles.module.scss";

const SocialLinks = () => {
    return (
        <div className={styles.socialIcons_block}>
            <a className={styles.facebook} href="https://www.facebook.com/New.LayerApp" target='_blank'></a>
            <a className={styles.instagram} href="https://www.instagram.com/new_layer_app/" target='_blank'></a>
            <a className={styles.subtract} href="https://newlayer.ar-generation.com/unilink/" target='_blank'></a>
        </div>
    );
};

export default SocialLinks