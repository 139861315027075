import React, {useEffect, useState} from "react";
import Footer from "../../components/Footer/Footer";
import * as styles from "./styles.module.scss";
import ScrollTopBtn from "../../components/UI/ScrollTopBtn";
import {useContextState} from "../../context/store";
import axios from "../../api/Api";
import ItemMapMark from "../../assets/Events/ItemMapMark";


const Event = ({pageContext}) => {
    const code = pageContext.code
    const {state} = useContextState()
    const [event, setEvent] = useState({})

    useEffect(() => {
        axios.get(`event/?code=${code}`).then(res => setEvent(res.data[0]))
    }, [])
    console.log(event)
    console.log(code)
    if (!event) return null

    return (
        <div className={styles.event_page_container}>
            <ScrollTopBtn/>
            <div className={styles.event_container}>
                <div className={styles.event_hero}>
                    <div className={styles.event_item_place_container}>
                        <ItemMapMark/>
                        <p className={styles.event_item_place}>{event.location}</p>
                    </div>
                    <h1>{event.title}</h1>
                    <p>{event.date_start} - {event?.date_end}</p>
                    <div className={styles.event_item_img_detail}>
                        <div className={styles.event_item_img_wrapper}>
                            <img src={`${event.img_detail}`} alt=""/>
                        </div>
                    </div>
                </div>
                <div className={styles.event_info_block} dangerouslySetInnerHTML={{__html: event.text}}>

                </div>
            </div>
            <Footer/>
        </div>
    )
}
export default Event


